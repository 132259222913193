.App {
  text-align: center;
  /*background-image:  url("./assets/images/background.jpg");*/
  background-color: #282c34; /* utiliza el fondo si la imagen no está disponible */
  height: 100vh; /* Altura */
  background-position: center; /* Centrado del background */
  background-repeat: no-repeat; /* Que no se repita la imagen */
  background-size: cover; /* Redimensiona el background para cubrir la totalidad del contenedor */
}

.App-logo {
  height: 18vh;
  pointer-events: none;
}

.App-loader {
  border: 16px solid #d4a41f;
  border-top: 16px solid #3498db;
  border-bottom: 16px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1.5s linear infinite;
  position: fixed;
  left : 42%;
  bottom : 0;
  right : 0;
  top : 45%;
}

.App-loader-background {
  min-width: 100vw;
  min-height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: #eaeaea4a;
  z-index: 9999;
}

h4 {
  font-size: 22px;
}
.avisos {
  padding-right: 5vh;
  padding-left: 5vh;
}

.App-header {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: static;
}

.App-body {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-body-fotocam {
  min-height: 60vh;
}

.App-footer {
  color: #fff;
  min-height: 3vh;
  position: relative;
}

.Resgistro-realizado {
  color: #000;
}

.App-welcome {
  background-image:  url("./assets/images/ventana-bienvenida.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 45vh;
  min-width: 40vh;
  border-radius: 3vh;
}

.App-welcome-title {
  padding-top: 2.8vh;
  font-size: 45px;
  color: rgb(12, 12, 73);
}

.App-welcome-text {
  padding-top: 2.5vh;
  font-size: 28px;
  color: rgb(12, 12, 73);
}

.App-boton-avanzar {
  top: 20px;
  text-align: center;
  margin-bottom: 5vh;
  font-size: 20px;
}

.App-welcomeuser {
  background-image:  url("./assets/images/ventana.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 45vh;
  min-width: 40vh;
  border-radius: 3vh;
  padding-bottom: 40px;
}

.App-welcomeuser-title {
  padding-top: 2vh;
  font-size: 30px;
  color: rgb(12, 12, 73);
}

.App-welcomeuser-text {
  font-size: 14px;
  color: rgb(12, 12, 73);
}

.App-conditions {
  background-color: #f0f0f0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 55vh;
  width: 40vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
}

.App-conditions-title {
  font-size: 16px;
  text-align: center;
  color: black;
  margin: 0.8em;
}

.App-conditions-text {
  height: 32vh;
  width: 36vh;
  font-size: 14px;
  color: rgb(12, 12, 73);
  overflow: auto;
  margin: 15px 15px 15px 15px;
  padding: 0px 10px 0px 0px;
  text-align: justify;
}

.continue-cookies {
  height: 50px;
  width: 300px;
  border-radius: 25px;
  margin-top: 15px;
}

.buttonblue {
  height: 5vh;
  border-radius: 25px;
  padding-top:5px;
}

.buttonblue-small {
  height: 5vh;
  border-radius: 25px;
  padding-top:5px;
}

.buttonyellow {
  height: 5vh;
  border-radius: 25px;
  padding-top:5px;
}

.buttonyellow-small {
  height: 5vh;
  border-radius: 25px;
  padding-top:5px;
}

.buttoneye {
  height: 9vh;
  padding-top:10px;
}

.admin-cookies {
  background-position: left top;
  background-repeat: no-repeat;
  height: 50px;
  width: 300px;
  border-radius: 25px;
  margin-top: 15px;
}

.App-cookies-settings {
  background-color: #f0f0f0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 55vh;
  width: 40vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
}

.App-cookies-title {
  font-size: 18px;
  color: black;
  text-align: left;
  margin: 0.8em;
}

.App-cookies-text {
  height: 35vh;
  width: 37vh;
  font-size: 14px;
  color: rgb(12, 12, 73);
  overflow: auto;
  margin: 15px 15px 15px 15px;
  padding: 0px 10px 0px 0px;
  text-align: left;
}

.App-link {
  color: #61dafb;
  padding-top: 0vh;
}

.contenedor{
  position: relative;
  display: inline-block;
  text-align: center;
}

.contenedor-thanks{
  position: relative;
  display: inline-block;
  text-align: center;
  margin-top: 2.5vh;
}

.boton-dinamico{
  position: relative;
  text-align: center;
}

.texto-encima{
  position: absolute;
  top: 10px;
  left: 10px;
}
.centrado{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:100%;
  font-size: 11px;
  color: black;
  font-weight: bold;
}

/*Diseño de la scrollbar*/
 /* width */
 ::-webkit-scrollbar {
  width: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00CED1;
}

/* Track */
::-webkit-scrollbar-track {
  background: #191970;
}

.scrollable-element {
  scrollbar-color: #00CED1 #191970;
}

::placeholder { color:#2020a1 }

textarea:focus, input:focus, input[type]:focus {
  border-color: rgb(0, 225, 255);
  box-shadow: 0 1px 1px rgba(255, 193, 22, 0.075)inset, 0 0 8px rgb(0, 225, 255);
  outline: 0 none;
}

.App-registro-text {
  height: 30vh;
  width: 37vh;
  font-size: 0.7em;
  color: rgb(12, 12, 73);
  overflow: auto;
  margin: 15px 15px 15px 15px;
  padding: 0px 0px 0px 0px;
  text-align: left;
}

.form-permisos-input {
  height: 30px;
  width: 30px;
  position: relative;
  padding-left: 100px;
  border: 0px;
  opacity: 0.5;
}

.form-permisos-input:checked {
  opacity: 1;
  background-color: rgb(255, 216, 40);
}

.form-permisos-label {
  position: relative;
  bottom: 10px;
  padding: 10px 0px 0px 10px;
}

.form-permisos-label-disabled {
  position: absolute;
  float: right;
  padding: 10px 0px 0px 10px;
  color:gray;
}

.form-permisos-text {
  position: relative;
  width: 90%;
  font-size: 10px !important;
  border-radius: 10px;
  border: 1px solid #ccc;
  margin-top: 10px;
  padding: 10px 10px 10px 10px;
}

.App-registro {
  background-color: #f0f0f0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 55vh;
  width: 40vh;
}

.form-registro {
  height: 50px;
  width: 34vh;
  margin-top: 1vh;
  margin-left: 1vh;
  border: 0px;
  background-color: #dfdfdf;
  border-bottom: 1px solid #000;
}

.output-data {
  color: #000;
  font-size: 11px;
}

.games-container {
  height: 40vh;
  width: 40vh;
}

.game-container {
  width: 400px;
}

.game {
  height: 14vh;
  width: auto;
  float: right;
  position: relative;
  padding-right: 1.5vh;
}

.boton-camara {
  height: 10vh;
  pointer-events: none;
  padding-top: 1.5vh;
}

.comentarios-label {
  font-size: 20px;
  color: #ffffff;
}

.comentarios {
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.comentariofoto {
  margin-top: 1vh;
  font-size: 18px;
  height: 200px;
  width: 300px;
  resize: none;
  border-radius: 10px;
  padding: 10px 10px 10px 10px ;
}

/*Banner*/
.App-banner-footer {
  height: 15vh;
  padding-top: 5vh;

}
.App-banner-footer-in-game {
  height: 12vh;
  padding-top: 0.5vh;
}

.App-thanks-user {
  background-image:  url("./assets/images/ventana-bienvenida.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 45vh;
  min-width: 40vh;
  border-radius: 3vh;
}

.App-thanks-user-title {
  padding-top: 1vh;
  font-size: 1.7em;
  padding: 10px 10px 10px 10px;
  color: rgb(12, 12, 73);
}

.App-thanks-user-text {
  padding-top: 2.5vh;
  font-size: 1.2em;
  color: rgb(12, 12, 73);
}

.game-quiz {
  background-image:  url("./assets/images/ventana.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 45vh;
  width: 40vh;
  border-radius: 3vh;
  
}

.game-quiz-title {
  padding-top: 2vh;
  font-size: 22px;
  color: rgb(12, 12, 73);
}

.game-quiz-text {
  padding-top: 18px;
  padding-bottom:  18px;
  color: rgb(12, 12, 73);
}

.game-quiz-answer {
  margin-bottom: 10px;
  margin-left: 5vh;
  text-align: left;
  font-size: 18px;
}

.option-quiz {
  height: 30px;
  border-radius: 25px;
  position: absolute;
}

.select-option-quiz {
  height: 45px;
  border-radius: 25px;
  position: relative;
  padding-top: 20px;
  margin-left: 10px;
}

.counter {
  font-size: 25px;
  color: #ffffff;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.counter-limit {
font-size: 25px;
color: red;
margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.counter-bufandeo { 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:100%;
  font-size: 42px;
  color: rgb(63, 0, 114);
  font-weight: bold; 
}

.counter-bufandeo-limit { 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:100%;
  font-size: 42px;
  color: red;
  font-weight: bold; 
}

.countdown {
  height: 10vh;
}

.bufandeo-hand {
  height: 30vh;
}

.bufandeo-turn {
  height: 8vh;
}

.spacer {
  padding-top: 1vh;
}

.gyroevents {
  color:rgb(255, 216, 40);
  font-size:small;
}

@media only screen and (min-width: 600px) {

}

@media only screen and (min-width: 768px) {
  .App-loader {
    left : 47%;
    top : 45%;
  }
}

@media (orientation: landscape){
  body:after{
    position: fixed;
    z-index: 999999;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    content: "";
    background: #212121 url("./assets/images/turn_device.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.97;
  }

  html, body {
    overflow: hidden !important;
  }

}